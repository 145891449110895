function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const object = {
  dictKey: "dict://",
  reCaptcha: "",
  localstorageKey: "cubiertasv1",
  endpoints: Object.freeze({
    auth: "auth",
    cubiertas: "cubiertas",
    ejes: "ejes",
    empresas: "empresas",
    historiales: "historiales",
    log: "log",
    marcas: "marcas",
    permisos: "permisos",
    rodados: "rodados",
    roles: "roles",
    rolesxpermisos: "rolesxpermisos",
    users: "users",
    vehiculos: "vehiculos",
  }),
  dictionaryIndexes: Object.freeze({
    cubiertas: 15,
    ejes: 9999,
    empresas: 9999,
    historiales: 9999,
    log: 9999,
    marcas: 9999,
    permisos: 9999,
    rodados: 9999,
    roles: 9999,
    rolesxpermisos: 9999,
    users: 15,
    vehiculos: 15,
  }),
  VAPIDPublicKey: urlBase64ToUint8Array(
    "BP2AliyxfTyuw6q_be_AdScBhjZN8USyE3V8QPHV3OKnjHARAKuXAboYfY5_yDL-CyBUtZik73sHuTGo3ELOa-I"
  ),
};

switch (process.env.NODE_ENV) {
  case "production":
    object.apiURL = "http://cubiertas.sistemas.nixel.com.ar/api/v1/";
    object.uploadsURL = "/uploads/";
    break;
  default:
    object.apiURL = "http://localhost/cubiertas/v1/";
    object.uploadsURL = "http://localhost/cubiertas/uploads/";
    break;
}

export default object;
